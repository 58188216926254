export const getPageOrder = (currentPage: number, lastPage: number, isDesktop: boolean) => {
  const res: Array<number> = [];
  const maxLength: number = isDesktop ? 7 : 5;

  if (lastPage <= maxLength) {
    for (let i = 1; i <= lastPage; i++) {
      res.push(i);
    }
  } else {
    const firstPage = 1;
    const arrayChunk = isDesktop ? 4 : 2;
    const middleTest = maxLength - arrayChunk;

    if (currentPage - firstPage <= arrayChunk) {
      for (let j = 1; j <= arrayChunk + firstPage; j++) {
        res.push(j);
      }

      res.push(NaN);
      res.push(lastPage);
    } else if (lastPage - currentPage <= arrayChunk) {
      res.push(firstPage);
      res.push(NaN);
      for (let j = lastPage - arrayChunk; j <= lastPage; j++) {
        res.push(j);
      }
    } else if (currentPage - firstPage >= middleTest && lastPage - currentPage >= middleTest) {
      if (isDesktop) {
        res.push(1);
        res.push(NaN);
      }

      for (let l = currentPage - 2; l <= currentPage + 2; l++) {
        res.push(l);
      }

      if (isDesktop) {
        res.push(NaN);
        res.push(lastPage);
      }
    }
  }

  return res;
};
