import { cn } from "@/lib/utils";

export const CustomButton = ({
  label,
  active,
  setActiveBtn,
  disabled,
}: {
  setActiveBtn: () => void;
  active: boolean;
  label: string;
  disabled?: boolean;
}) => (
  <button
    id="button-custom-core"
    disabled={disabled}
    onClick={setActiveBtn}
    className={cn(
      `text-white after:absolute after:h-[2px] after:-bottom-1 
        after:left-0 after:right-0 after:origin-top-left relative after:bg-mellow after:transition 
        font-saira outline-none focus-visible:outline-mellow outline-offset-2
        text-[18px] font-[500] leading-[1.54] tracking-[-0.1]`,
      active ? "text-mellow after:scale-x-100" : "text-white after:scale-x-0"
    )}
  >
    {label}
  </button>
);
