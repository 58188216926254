/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { Input } from "../ui/input";
import { LocateFixedIcon, Search } from "lucide-react";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import { OutputFormat, fromLatLng, setDefaults } from "react-geocode";
import { toast } from "sonner";

setDefaults({
  key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API!,
  language: "en",
  outputFormat: OutputFormat.JSON,
});

export const CustomInputLocation = ({
  placeholder,
  setSelectedPlace,
  handleLocationOnChange,
  name,
  disabled,
  handleAddressBlur,
  clearAddressInput,
  useMyLocationTooltip,
}: {
  placeholder: string;
  useMyLocationTooltip: string;
  setSelectedPlace: React.Dispatch<React.SetStateAction<any>>;
  handleLocationOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAddressBlur: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
  clearAddressInput: number;
}) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useMapsLibrary("places");

  useEffect(() => {
    if (inputRef?.current && inputRef.current.value) {
      inputRef.current.value = "";
      toast.error("Please select location from suggestions list");
    }
  }, [clearAddressInput]);

  useEffect(() => {
    const value = name ?? "";
    inputRef.current && (inputRef.current.value = value);
  }, [name]);

  useEffect(() => {
    if (!places || !inputRef.current) return;

    const options = {
      componentRestrictions: { country: ["us", "ca"] },
      fields: ["name", "formatted_address", "geometry"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      setSelectedPlace(placeAutocomplete.getPlace());
    });
  }, [setSelectedPlace, placeAutocomplete]);

  const handleMyLocation = async () => {
    if (disabled) return;

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const data = await fromLatLng(position.coords.latitude, position.coords.longitude);
          inputRef?.current && (inputRef.current.value = data.results?.[0]?.formatted_address);

          setSelectedPlace({
            fromBrowser: true,
            formatted_address: data.results?.[0]?.formatted_address,
            coords: {
              lat: data.results?.[0]?.geometry?.location?.lat,
              lng: data.results?.[0]?.geometry?.location?.lng,
            },
          });
        },
        (error) => {
          if (error.code == error.PERMISSION_DENIED) {
            console.log("[GEOLOCATION ERROR]: Blocked by user");
          }
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  };

  return (
    <div className="border-b-[1px] relative customInput">
      <Input
        ref={inputRef}
        onBlur={handleAddressBlur}
        aria-label="search"
        onChange={handleLocationOnChange}
        type="search"
        placeholder={placeholder}
        disabled={disabled}
        autoComplete="off"
        role="searchbox"
        className={`px-8 placeholder:text-navy-blue-10 font-saira font-[500] focus-visible:outline-white 
      text-[16px] leading-[1.75] tracking-[-0.1px] text-white bg-transparent 
      border-white border-none rounded-none ring-offset-royal-blue`}
      />

      <Search size={20} className="top-[10px] absolute left-0 text-white" />

      <button
        title={useMyLocationTooltip}
        onClick={handleMyLocation}
        className="top-[6px] outline-mellow absolute right-0 text-white p-1"
      >
        <LocateFixedIcon size={20} />
      </button>
    </div>
  );
};

export const CustomInputUnit = ({
  placeholder,
  value,
  onChange,
  handleUnitIdKeyDown,
}: {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  handleUnitIdKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void;
}) => {
  return (
    <div className="border-b-[1px] relative customInput">
      <Input
        aria-label="search"
        type="search"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value || "")}
        onKeyDown={handleUnitIdKeyDown}
        className={`px-8 placeholder:text-navy-blue-10 font-saira font-[500]    
      text-[16px] leading-[1.75] tracking-[-0.1px] text-white bg-transparent outline-none
      border-white border-none rounded-none ring-offset-royal-blue ring-white
      `}
      />
      <Search size={20} className="top-[10px] absolute left-0 text-white" />
    </div>
  );
};
