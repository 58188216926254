/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import Image from "next/image";

import AppText from "../other/AppText";
import { AppIconButton } from "../buttons/AppIconButton";

import { Separator } from "../ui/separator";
import { AppButton } from "../buttons/AppButton";
import { ChevronRight } from "lucide-react";
import { cn } from "@/lib/utils";

const ShowroomCard = ({ card, usedProductMoreLikeThis }: { card: any; usedProductMoreLikeThis?: boolean }) => {
  const numberFormatter = new Intl.NumberFormat();

  return (
    <div
      className={cn(
        "sm:col-span-2 lg:col-span-3 animate-in fade-in  flex flex-col h-full col-span-4 bg-white",
        usedProductMoreLikeThis && "p-4 bg-black-5"
      )}
    >
      <div className="relative mb-4 overflow-hidden">
        <div className="relative aspect-[16/9] ">
          <Image
            src={card.image}
            alt={card.alt}
            sizes="(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 320px"
            fill
            className="group-hover:scale-105 object-cover w-full transition"
            placeholder="blur"
            blurDataURL={card.placeholder}
          />
        </div>
        {card.obo && (
          <div className="bg-mellow top-8 rounded-l-2xl absolute right-0 px-4 py-1 font-sairaSemiCondensed text-14 font-[500] uppercase tracking-[1.5px]">
            OBO
          </div>
        )}
      </div>

      <div className="gap-x-4 bap-y-1 flex flex-wrap items-center mb-4">
        <AppText type="LABEL_LARGE" className="text-royal-blue">
          {card.branchName || "N/A"}
        </AppText>
        <Separator orientation="vertical" className="h-[28px] bg-[#999]" />

        <AppText type="BODY_MEDIUM" className="">
          {card.unitNumber}
        </AppText>
      </div>

      <AppText type="TITLE_SMALL" className="mb-[10px] normal-case">
        {card.name}
      </AppText>

      {card.discountedPrice ? (
        <div className="flex flex-wrap items-center gap-4 mt-auto mb-6">
          <span className="text-18 line-through">${numberFormatter.format(card.price)}</span>

          <AppText type="BODY_LARGE_EMPHASIS" className="text-[#DE350B] !font-saira text-18 font-500 tracking-[-0.1px]">
            ${numberFormatter.format(card.discountedPrice)}
          </AppText>
        </div>
      ) : (
        <AppText type="BODY_LARGE_EMPHASIS" className="mt-auto mb-6">
          ${numberFormatter.format(card.price)}
        </AppText>
      )}

      <div className="gap-x-4 gap-y-1 flex flex-wrap">
        <AppButton label={card.purchaseInquiry} intent="primary" link={`/request-quote-buy/${card.unitNumber}`} />

        <AppIconButton
          tabIndex={-1}
          intent="ghost"
          iconOnRightSide
          icon={<ChevronRight size={20} aria-label="chevron right icon" />}
          label={card.viewUnit}
          className="after:left-0 after:right-0 px-0"
          link={`/sales-showroom/${card.slug}`}
        />
      </div>
    </div>
  );
};

export default ShowroomCard;
