import React from "react";

const LoadingDots = () => (
  <div className="flex items-center">
    <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
    <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
    <span className="searchDots bg-white rounded-full h-3 w-3 m-2 animate-[dot_1s_ease-in-out_infinite]"></span>
  </div>
);

export default LoadingDots;
