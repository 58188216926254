export const DISTANCE = ["within25miles", "within50miles", "within100miles", "within200miles", "200_miles"];

export const UNITS = [
  "Classroom",
  "Container Office",
  "Panelized Product",
  "Office Trailer",
  "Sales Office",
  "Storage Container",
  "Toilet Trailer",
  "Bunkhouse",
  "Clean Room",
  "Pump House",
  "Guard House",
  "IN Plant Office",
  "Van Trailer",
  "Cold Storage",
];

export const PRICE = ["0_10000", "10000_25000", "25000_50000", "50000_75000", "75000+"];

export enum ESelect {
  "distance",
  "unitType",
  "priceRange",
}

export const SORT_MAP: { [key: string]: string } = {
  "Price (Low to High)": "fields.price",
  "Price (High to Low)": "-fields.price",
  "Newest to Oldest": "sys.updatedAt",
  "Oldest to Newest": "-sys.updatedAt",
};

export const DISTANCE_MAP: { [key: string]: number } = {
  within25miles: 40.2,
  within50miles: 80.5,
  within100miles: 161,
  within200miles: 322,
};
